










































































































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, minValue, required } from 'vuelidate/lib/validators';
import UrlPathValidator from '@/services/validators/UrlPathValidator';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditNamePartViewModel {
  id!: string;
  name!: string;
  shortDescription!: string;
  urlPath!: string;
  hideAddressTillConfirmation!: boolean;
  companyUrlPath!: string;
  areaInSquareMeter!: number;
  roomCount!: number;
  availablePropertyTypes!: string[];
}

const validations = {
  playroom: {
    name: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(100),
    },
    shortName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(20),
    },
    urlPath: {
      UrlPathValidator,
      maxLength: maxLength(100),
    },
    shortDescription: {
      maxLength: maxLength(1000),
    },
    roomCount: {
      required,
      minValue: minValue(1),
    },
    areaInSquareMeter: {
      required,
      minValue: minValue(1),
    },
    propertyType: {},
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, ValidatedTextArea, ValidatedFormCard, ValidatedSelect },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditNamePartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditNamePart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditNamePartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveNamePart({ ...this.playroom });
        if (response.success) {
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },

    onFocusChange(name: string) {
      switch (name) {
        case 'name': {
          this.focusFor = 'components.playroomEditParts.NamePart.info.box1';
          break;
        }
        case 'shortDescription': {
          this.focusFor = 'components.playroomEditParts.NamePart.info.box2';
          break;
        }
        case 'areaInSquareMeter':
        case 'roomCount': {
          this.focusFor = 'components.playroomEditParts.NamePart.info.box3';
          break;
        }
        case 'urlPath': {
          this.focusFor = 'components.playroomEditParts.NamePart.info.box4';
          break;
        }
        default: {
          this.focusFor = '';
        }
      }
    },
  },
  computed: {
    publicUrl(): string {
      var domain = process.env.VUE_APP_PUBLIC_BASE_URL;
      var playroomUrlPath = this.playroom.urlPath;
      return domain + '/playrooms/' + playroomUrlPath;
    },
    propertyTypeOptions(): { value: string; label: string }[] {
      if (this.ready) {
        return this.playroom.availablePropertyTypes.map((t) => ({
          label: this.$t('enums.propertyTypes.' + t).toString(),
          value: t,
        }));
      } else {
        return [];
      }
    },
  },
});
