







































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import { requiredIf, url } from 'vuelidate/lib/validators';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditVideoPartViewModel {
  id!: string;
  videoType!: string;
  videoUrl!: string;
  availableVideoTypes!: string[];
}

const validations = {
  playroom: {
    videoType: {},
    videoUrl: {
      required: requiredIf((playroom) => playroom.videoType != 'None'),
      url,
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, ValidatedSelect, ValidatedFormCard },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditVideoPartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    availableVideoTypes(): { label: string; value: string }[] {
      return this.playroom.availableVideoTypes.map((videoType) => {
        const translation = this.$t('enums.videoTypes.' + videoType).toString();
        return { label: translation, value: videoType };
      });
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditVideoPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditVideoPartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveVideoPart({ ...this.playroom });
        if (response.success) {
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
