





















































































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import AddressFormValidations from '@/validations/AddressFormValidations';
import AddressForm from '@/components/forms/AddressForm.vue';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import OwnerAddressService from '@/services/owner/OwnerAdressService';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditAddressViewModel {
  addressRadius!: number;
  city!: string;
  country!: string;
  id!: string;
  state!: string;
  street!: string;
  houseNumber!: string;
  zipCode!: string;
  hideAddressTillConfirmation!: boolean;
  latitude!: number;
  longitude!: number;
}

const validations = {
  playroom: {
    ...AddressFormValidations,
    hideAddressTillConfirmation: {},
    latitude: {},
    longitude: {},
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, AddressForm, ValidatedCheckbox, ValidatedFormCard },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: { latitude: 1, longitude: 1 } as PlayroomEditAddressViewModel,
      ready: false,
      originalLatitude: 0,
      originalLongitude: 0,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    'playroom.hideAddressTillConfirmation': {
      handler(newValue: boolean) {
        if (newValue) {
          this.movePointToNearRandomPosition();
        } else {
          this.playroom.latitude = this.originalLatitude;
          this.playroom.longitude = this.originalLongitude;
        }
      },
    },
  },
  methods: {
    onChange() {
      if (!this.$v.$invalid) {
        // Geocode new Address
        const addressObj = {
          street: this.playroom.street,
          houseNumber: this.playroom.houseNumber,
          city: this.playroom.city,
          state: this.playroom.state,
          zipCode: this.playroom.zipCode,
          country: this.playroom.country,
        };
        OwnerAddressService.geocodeAddress(addressObj).then((data) => {
          // Save Lat and Lng
          this.playroom.latitude = data.value.latitude;
          this.playroom.longitude = data.value.longitude;
          this.originalLatitude = data.value.latitude;
          this.originalLongitude = data.value.longitude;
          if (this.playroom.hideAddressTillConfirmation) {
            this.movePointToNearRandomPosition();
          }
        });
      }
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.AddressPart.info.box1';
      }
    },

    movePointToNearRandomPosition(): void {
      // Latitude per Kilometer
      const latKm = 1 / 111;
      const randomLatitude = this.getRandomArbitrary(-latKm, latKm);
      this.playroom.latitude += randomLatitude;
      // Longitude per Kilometer
      const lngKm = 1 / (111 * Math.cos(this.playroom.latitude));
      const randomLongitude = this.getRandomArbitrary(-lngKm, lngKm);
      this.playroom.longitude += randomLongitude;
    },

    getRandomArbitrary(min: number, max: number) {
      return Math.random() * (max - min) + min;
    },

    positionChanged(data: { latLng: { lat: () => number; lng: () => number } }) {
      this.playroom.latitude = data.latLng.lat();
      this.playroom.longitude = data.latLng.lng();
      this.$v.playroom.$touch();
    },

    loadData() {
      OwnerPlayroomService.getForEditAddressPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditAddressViewModel;
        this.originalLatitude = this.playroom.latitude;
        this.originalLongitude = this.playroom.longitude;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const response = await OwnerPlayroomService.saveAddressPart({ ...this.playroom });
        if (response.success) {
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
