







































import Vue from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import NamePart from '@/components/playroomEditParts/NamePart.vue';
import ContactPart from '@/components/playroomEditParts/ContactPart.vue';
import VideoPart from '@/components/playroomEditParts/VideoPart.vue';
import AddressPart from '@/components/playroomEditParts/AddressPart.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { PartComponent } from '@/models/PartComponent';
import Notification from '@/components/shared/Notification.vue';
import { MetaInfo } from 'vue-meta';

class PlayroomEditBasicInformationViewModel {
  id!: string;
  name!: string;
}

export default Vue.extend({
  components: { NamePart, AddressPart, Notification, ContactPart, VideoPart },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
    newPlayroom: {
      type: String,
      required: false,
      default: 'false',
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditBasicInformationViewModel,
      namePartSaveStatusType: PartSaveStatusType.Unknown,
      contactPartSaveStatusType: PartSaveStatusType.Unknown,
      videoPartSaveStatusType: PartSaveStatusType.Unknown,
      addressPartSaveStatusType: PartSaveStatusType.Unknown,
      showNewPlayroomInformation: this.newPlayroom == 'true',
    };
  },
  async mounted() {
    await this.loadData();
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostPlayroomEdit.meta.title', { name: this.playroom.name }).toString(),
    };
  },
  computed: {
    canSave(): boolean {
      return (
        this.namePartSaveStatusType != PartSaveStatusType.Invalid &&
        this.addressPartSaveStatusType != PartSaveStatusType.Invalid &&
        this.contactPartSaveStatusType != PartSaveStatusType.Invalid &&
        this.videoPartSaveStatusType != PartSaveStatusType.Invalid
      );
    },
    isDirty(): boolean {
      return (
        this.namePartSaveStatusType == PartSaveStatusType.Unsaved ||
        this.namePartSaveStatusType == PartSaveStatusType.Invalid ||
        this.addressPartSaveStatusType == PartSaveStatusType.Unsaved ||
        this.addressPartSaveStatusType == PartSaveStatusType.Invalid ||
        this.contactPartSaveStatusType == PartSaveStatusType.Unsaved ||
        this.contactPartSaveStatusType == PartSaveStatusType.Invalid ||
        this.videoPartSaveStatusType == PartSaveStatusType.Unsaved ||
        this.videoPartSaveStatusType == PartSaveStatusType.Invalid
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  methods: {
    async loadData() {
      const res = await OwnerPlayroomService.getForEditBasicInformation(this.playroomId);
      this.playroom = res.value as PlayroomEditBasicInformationViewModel;
      this.setBreadCrumb();
      return res;
    },

    setBreadCrumb() {
      const items = [
        { to: { name: 'OwnerPlayroomIndex' }, translationKey: 'pages.host.HostPlayroomEdit.breadcrumb.playrooms' },
        { text: this.playroom.name },
        { translationKey: 'global.change' },
      ];
      this.$store.commit('UiStoreModule/setBreadcrumbItems', items);
    },

    async onNext() {
      (this.$refs.namePart as PartComponent).$v.$touch();
      (this.$refs.addressPart as PartComponent).$v.$touch();
      (this.$refs.contactPart as PartComponent).$v.$touch();
      (this.$refs.videoPart as PartComponent).$v.$touch();
      this.$nextTick(async () => {
        const success = await this.saveAndNext();
        if (!success) {
          // Scroll to first error
          const element = document.getElementsByClassName('invalid-feedback')[0];
          if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'nearest' });
          }
        }
      });
    },

    async saveAndNext(): Promise<boolean> {
      if (this.canSave) {
        let success = await this.savePart(this.$refs.namePart as PartComponent, this.namePartSaveStatusType);
        if (!success) return false;
        success = await this.savePart(this.$refs.addressPart as PartComponent, this.addressPartSaveStatusType);
        if (!success) return false;
        success = await this.savePart(this.$refs.contactPart as PartComponent, this.contactPartSaveStatusType);
        if (!success) return false;
        success = await this.savePart(this.$refs.videoPart as PartComponent, this.videoPartSaveStatusType);
        if (!success) return false;

        window.scrollTo(0, 0);
        this.$router.push({ name: 'OwnerPlayroomEditDetailInformation', params: { playroomId: this.playroomId } });
      }
      return true;
    },

    async savePart(component: PartComponent, partSaveStatusType: PartSaveStatusType): Promise<boolean> {
      switch (partSaveStatusType) {
        case PartSaveStatusType.Unknown:
        case PartSaveStatusType.Unsaved:
        case PartSaveStatusType.Unchanged: {
          return await component.externalSave();
        }
        case PartSaveStatusType.Saved: {
          return true;
        }
        default: {
          return false;
        }
      }
    },

    confirmLeave() {
      return window.confirm(this.$t('global.confirmUnsavedChanges').toString());
    },

    confirmStayInDirtyForm() {
      return this.isDirty && !this.confirmLeave();
    },

    beforeWindowUnload(e: Event) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = true;
      }
    },
  },
});
