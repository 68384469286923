


















































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, email, required, url } from 'vuelidate/lib/validators';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import PhoneNumberValidator from '@/validators/PhoneNumberValidator';

class PlayroomEditContactPartViewModel {
  id!: string;
  phoneNumber!: string;
  email!: string;
  website!: string;
}

const validations = {
  playroom: {
    phoneNumber: { required, maxLength: maxLength(20), PhoneNumberValidator },
    website: { maxLength: maxLength(100), url },
    email: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(50),
      email,
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, ValidatedFormCard },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditContactPartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    playroom: {
      deep: true,
      handler() {
        this.playroom.email = this.playroom.email.trim();
      },
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditContactPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditContactPartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const response = await OwnerPlayroomService.saveContactPart({ ...this.playroom });
        if (response.success) {
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
